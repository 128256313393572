import React, { useState, useRef } from "react";
import emailjs from "emailjs-com";

import "./Contact.scss";

import config from "../../index.json";

const Contact = (props) => {
  const form = useRef();

  // input states
  const [gender, setGender] = useState("");
  const [prename, setPrename] = useState("");
  const [postname, setPostname] = useState("");
  const [address, setAddress] = useState("");
  const [zip, setZip] = useState("");
  const [mail, setMail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  // contact states
  const [error, setError] = useState(null);
  const [mailSent, setMailSent] = useState(false);
  const [sending, setSending] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    setSending(true);

    emailjs
      .sendForm(
        "service_crz61ft",
        props.pagename === "sta" ? "template_nd6aqno" : "template_2b8t6do",
        form.current,
        "yQiRViWTy5Ggc8MBW"
      )
      .then(
        (result) => {
          setSending(false);
          setMailSent(true);
        },
        (error) => {
          setSending(false);
          setError(true);
        }
      );
  };

  const componentName = `${props.pagename}-contact`;

  return (
    <div className={componentName}>
      <div className={`${componentName}__infos`}>
        <div className={`${componentName}__name`}>{props.name}</div>
        {props.contacts &&
          props.contacts.map((contact, index) => (
            <div
              className={`${componentName}__item`}
              key={`${componentName}__item-${index}`}
            >
              <div className={`${componentName}__item-label`}>
                {contact.label}
              </div>
              {contact.href ? (
                <a
                  href={contact.href}
                  className={`${componentName}__item-value`}
                >
                  {contact.value}
                </a>
              ) : (
                <div className={`${componentName}__item-value`}>
                  {contact.value}
                </div>
              )}
            </div>
          ))}
      </div>
      <div className={`${componentName}__form`}>
        {mailSent ? (
          <div className={`${componentName}__form-headline`}>
            {props.sendMessage || "Vielen Dank für Ihre Nachricht"}
          </div>
        ) : (
          <React.Fragment>
            <div className={`${componentName}__form-headline`}>
              {props.form.headline}
            </div>
            <div className={`${componentName}__form-subline`}>
              {props.form.subline}
            </div>
            <form
              id="form"
              ref={form}
              name="kontaktform"
              onSubmit={(e) => sendEmail(e)}
              className={`${componentName}__formular`}
            >
              <div className={`${componentName}__formular-radio`}>
                <span>HERR </span>
                <input
                  type="radio"
                  name="Anrede"
                  value="Herr"
                  checked={gender === "Herr"}
                  onChange={(e) => {
                    if (gender !== "Herr") {
                      setGender("Herr");
                    }
                  }}
                />
                <span> FRAU</span>
                <input
                  type="radio"
                  name="Anrede"
                  value="Frau"
                  checked={gender === "Frau"}
                  onChange={(e) => {
                    if (gender !== "Frau") {
                      setGender("Frau");
                    }
                  }}
                />
              </div>

              <div className={`${componentName}__formular-inputs`}>
                <input
                  type="text"
                  id="Vorname"
                  name="Vorname"
                  placeholder="Vorname"
                  value={prename}
                  onChange={(e) => setPrename(e.target.value)}
                />
                <input
                  type="text"
                  id="Nachname"
                  name="Nachname"
                  placeholder="Nachname"
                  value={postname}
                  onChange={(e) => setPostname(e.target.value)}
                />

                <input
                  type="text"
                  id="Adresse"
                  name="Adresse"
                  placeholder="Adresse und Hausnummer"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
                <input
                  type="text"
                  id="PLZ"
                  name="PLZ"
                  placeholder="Postleitzahl und Ort"
                  value={zip}
                  onChange={(e) => setZip(e.target.value)}
                />

                <input
                  type="email"
                  id="Email"
                  name="Email"
                  required
                  placeholder="Emailadresse*"
                  value={mail}
                  onChange={(e) => setMail(e.target.value)}
                />
                <input
                  type="text"
                  id="Telefon"
                  name="Telefon"
                  placeholder="Telefon"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>

              <textarea
                type="text"
                id="Nachricht"
                name="Nachricht"
                required
                placeholder="Ihre Nachricht an uns*"
                rows="5"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />

              {error && (
                <span className={`${componentName}__form-error`}>
                  {props.form.errorMessage ||
                    "Ein Fehler ist aufgetreten. Versuchen Sie es später nochmal."}
                </span>
              )}

              {sending && (
                <span className={`${componentName}__form-sending`}>
                  Wird gesendet...
                </span>
              )}

              <input
                className={`${componentName}__formular-submit`}
                name="Abschicken"
                type="submit"
                value="Abschicken"
                disabled={sending}
              />
            </form>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default Contact;
