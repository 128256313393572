import React from "react";

import "./Download.scss";

const Download = (props) => {
  const componentName = `${props.pagename}-download`;

  return (
    <div className={componentName}>
      {props.downloads &&
        props.downloads.map((download, index) => (
          <div className={`${componentName}__item`} key={`download-${index}`}>
            <span className={`${componentName}__item-headline`}>
              {download.headline}
            </span>
            <div className={`${componentName}__button-wrapper`}>
              <a
                href={`${download.src}.zip`}
                className={`${componentName}__load-button`}
                download
              >
                <strong className={`${componentName}__load-button-text`}>
                  Download
                </strong>
                <span className={`${componentName}__load-button-info`}>
                  {download.size} kb - PDF
                </span>
              </a>
              <a
                className={`${componentName}__view-button`}
                href={download.src}
                target="_blank"
              />
            </div>
          </div>
        ))}
    </div>
  );
};

export default Download;
