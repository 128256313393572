import React from "react";

import "./Footer.scss";

const Footer = (props) => {
  const componentName = `${props.pagename}-footer`;
  const scrollToTop = (e) => {
    window.scrollTo(0, 0);
  };

  return (
    <div className={componentName}>
      <div className={`${componentName}__content`}>
        <span className={`${componentName}__item`} onClick={() => scrollToTop()}>
          <strong>Seitenanfang</strong>
        </span>
        {props?.items?.map((item, index) => {
          if (item.openId) {
            return (
              <div
                className={`${componentName}__item`}
                key={`${componentName}__item-${index}`}
                onClick={() => {
                  scrollToTop();
                  props.openID(item.openId);
                }}
              >
                <strong>{item.label}</strong>
              </div>
            );
          }

          return (
            <a
              href={item.href}
              className={`${componentName}__item`}
              key={`${componentName}__item-${index}`}
              onClick={() => {
                scrollToTop();
              }}
            >
              <strong>{item.label}</strong>
            </a>
          );
        })}
      </div>
      {props.copyright && (
        <div className={`${componentName}__copyright`}>{props.copyright}</div>
      )}
    </div>
  );
};

export default Footer;
