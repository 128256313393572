import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Helmet } from "react-helmet";

import "../../stylesheets/main.scss";

import "./Homepage.scss";

// components
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Contact from "../../components/Contact/Contact";
import Download from "../../components/Download/Download";
import InfoArea from "../../components/InfoArea/InfoArea";

// setup
import setup from "../../../setup/index.json";

// data
import dataSta from "./data/01-sta-altona.json";
import dataHeh from "./data/02-heh-treuhand.json";
const data = setup.dist === "sta-altona" ? dataSta : dataHeh;

// sta and heh config map
import config from "../../index.json";
const pageData = config[data.pagename];

function Homepage() {
  const [activePage, setActivePage] = useState(0);
  const [headerOpen, setHeaderOpen] = useState(false);

  return (
    <div
      className={`${data.pagename}-wrapper${
        data.header.small ? ` ${data.pagename}-wrapper--small` : ""
      }`}
    >
      {data.title && (
        <Helmet>
          <title>
            {pageData.title} - {data.title}
          </title>
        </Helmet>
      )}
      <div className={`${data.pagename}-bg`}>
        {data.claim && (
          <span className={`${data.pagename}-claim`}>{data.claim}</span>
        )}
      </div>
      <Header
        {...data.header}
        pagename={data.pagename}
        headerOpen={headerOpen}
        activePage={activePage}
        clickCallback={(link) => {
          if (link) {
            if (link.id && activePage !== link.id) {
              setActivePage(link.id);
              setHeaderOpen(!headerOpen);
            }
          } else {
            setHeaderOpen(!headerOpen);
          }
        }}
      />
      <div className={`${data.pagename}-content`}>
        {data.content.map((content) => (
          <React.Fragment key={`content-${content.id}`}>
            {content.items &&
              content.items.map((item, index) => (
                <InfoArea
                  key={`infoarea-${index}`}
                  visible={content.id === activePage}
                  id={index}
                  pagename={data.pagename}
                  image={item.image ? item.image : null}
                >
                  {item.headline && <h2>{item.headline}</h2>}
                  {item.subline && (
                    <span className={`${data.pagename}-content__subline`}>
                      {item.subline}
                    </span>
                  )}

                  {item.contents.map((content) => {
                    if (content.type === "copies" && content.copies) {
                      return (
                        <React.Fragment>
                          {content.copies.map((text, index) => (
                            <p
                              key={`text-${index}`}
                              dangerouslySetInnerHTML={{ __html: text }}
                            />
                          ))}
                        </React.Fragment>
                      );
                    }

                    if (content.type === "list" && content.list) {
                      return (
                        <ul key={`text-${index}`}>
                          {content.list.map((text, innerIndex) => (
                            <li
                              dangerouslySetInnerHTML={{ __html: text }}
                              key={`li-${innerIndex}`}
                            />
                          ))}
                        </ul>
                      );
                    }
                  })}
                </InfoArea>
              ))}

            {content.type === "contact" && content.informations && (
              <InfoArea
                visible={content.id === activePage}
                id={content.id}
                pagename={data.pagename}
              >
                <Contact
                  {...content.informations}
                  form={content.form}
                  pagename={data.pagename}
                />
              </InfoArea>
            )}

            {content.type === "downloads" && content.downloads && (
              <InfoArea
                visible={content.id === activePage}
                id={content.id}
                pagename={data.pagename}
              >
                <Download
                  downloads={content.downloads}
                  pagename={data.pagename}
                />
              </InfoArea>
            )}
          </React.Fragment>
        ))}
      </div>
      <div
        className={`${data.pagename}-overlay${
          headerOpen ? ` ${data.pagename}-overlay--active` : ""
        }`}
      />
      {data.footer && (
        <Footer
          {...data.footer}
          openID={(page) => setActivePage(page)}
          pagename={data.pagename}
        />
      )}
    </div>
  );
}

ReactDOM.render(<Homepage />, document.getElementById("app")); // eslint-disable-line no-undef
