import React, { useState, useEffect } from "react";
import CX from "classnames";

import "./Header.scss";

const Header = (props) => {
  const componentName = `${props.pagename}-header`;
  const classNames = CX(`${componentName}`, {
    [`${componentName}--collapsed`]: !props.headerOpen,
    [`${componentName}--opened`]: props.headerOpen,
    [`${componentName}--small`]: props.small,
  });

  return (
    <header className={classNames}>
      <div className={`${componentName}__content`}>
        {!props.homeLink ? (
          <div className={`${componentName}__logo`}>
            <div
              className={`${componentName}__logo-img`}
              onClick={() => {
                props.clickCallback({ id: "home" });
              }}
            />
            <div
              className={`${componentName}__burger${
                props.headerOpen ? ` ${componentName}__burger--active` : ""
              }`}
              onClick={() => {
                props.clickCallback();
              }}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
        ) : (
          <a href="/" className={`${componentName}__logo`}>
            <div
              className={`${componentName}__logo-img`}
            />
            <div
              className={`${componentName}__burger${
                props.headerOpen ? ` ${componentName}__burger--active` : ""
              }`}
              onClick={() => props.clickCallback()}
            >
              <span />
              <span />
              <span />
            </div>
          </a>
        )}

        {!props.noLinks && (
          <div className={`${componentName}__items`}>
            {props?.items?.map((item, index) => (
              <div
                className={`${componentName}__item${
                  props.activePage === item.id
                    ? ` ${componentName}__item--active`
                    : ""
                }`}
                key={`${componentName}__item-${index}`}
                onClick={() => props.clickCallback(item)}
              >
                <span className={`${componentName}__item-headline`}>
                  {item.topic}
                </span>
                <p className={`${componentName}__item-text`}>
                  {item.description}
                </p>
              </div>
            ))}
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
