import React from "react";
import PropTypes from "prop-types";
import CX from "classnames";

import "./InfoArea.scss";

const InfoArea = (props) => {
  const componentName = `${props.pagename}-infoarea`;
  const classNames = CX(`${componentName}`, {
    [`${componentName}--visible`]: props.visible,
  });

  if (!props.visible) {
    return null;
  }

  const renderImage = (image, position) => {
    return (
      <div className={`${componentName}__image-wrapper`}>
        <img className={`${componentName}__image`} src={image.src} alt={image.alt} />
        <br />
        {image.description && (
          <span className={`${componentName}__image-description`}>
            {image.description}
          </span>
        )}
      </div>
    );
  };

  return (
    <div
      className={classNames}
      style={props.visible ? { transitionDelay: `${props.id * 0.5}s` } : {}}
    >
      <div className={`${componentName}__content`}>
        <hr />
        <div className={`${componentName}__triangle`} />
        <div
          className={`${componentName}__informations${
            props.image && props.image.position
              ? ` ${componentName}__informations--image-${props.image.position}`
              : ""
          }`}
        >
          {props.image &&
            (props.image.position === "left" ||
              props.image.position === "top") &&
            renderImage(props.image, props.image.position)}
          <div className={`${componentName}__children`}>{props.children}</div>
          {props.image &&
            (props.image.position === "right" ||
              props.image.position === "bottom") &&
            renderImage(props.image, props.image.position)}
        </div>
      </div>
    </div>
  );
};

export default InfoArea;
